// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run npm run generate:types to update

import { gql } from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** Money specific format: string-represented floating numbers */
  Money: any
}

export type About = {
  __typename?: 'About'
  buildDate?: Maybe<Scalars['String']>
  buildVersion?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export enum AccessPermissions {
  AssignTariff = 'assignTariff',
  CancelReport = 'cancelReport',
  CreateBankingPayment = 'createBankingPayment',
  CreateBankingProduct = 'createBankingProduct',
  CreateClient = 'createClient',
  CreateEntryOrder = 'createEntryOrder',
  CreateGame = 'createGame',
  CreateGameCategory = 'createGameCategory',
  CreateJackpot = 'createJackpot',
  CreateLocation = 'createLocation',
  CreateOperationReport = 'createOperationReport',
  CreatePayment = 'createPayment',
  CreatePaymentReport = 'createPaymentReport',
  CreateSystem = 'createSystem',
  CreateTariff = 'createTariff',
  CreateTeam = 'createTeam',
  CreateTemplate = 'createTemplate',
  CreateTransactionReport = 'createTransactionReport',
  CreateUser = 'createUser',
  DownloadReport = 'downloadReport',
  GetActiveBankingAgreement = 'getActiveBankingAgreement',
  GetAgreements = 'getAgreements',
  GetAvailableTypeList = 'getAvailableTypeList',
  GetClientList = 'getClientList',
  GetControlledClients = 'getControlledClients',
  GetCurrencyList = 'getCurrencyList',
  GetGameCategoryList = 'getGameCategoryList',
  GetGameList = 'getGameList',
  GetHitList = 'getHitList',
  GetIntegrantList = 'getIntegrantList',
  GetJackpotById = 'getJackpotByID',
  GetJackpotEntryList = 'getJackpotEntryList',
  GetJackpotList = 'getJackpotList',
  GetLevelList = 'getLevelList',
  GetLevelMeters = 'getLevelMeters',
  GetLocationList = 'getLocationList',
  GetPaymentList = 'getPaymentList',
  GetProductAccess = 'getProductAccess',
  GetProductList = 'getProductList',
  GetRelevantJackpotList = 'getRelevantJackpotList',
  GetReportList = 'getReportList',
  GetReportRecentTypeList = 'getReportRecentTypeList',
  GetServiceInvoicesList = 'getServiceInvoicesList',
  GetSystemKeys = 'getSystemKeys',
  GetSystemList = 'getSystemList',
  GetTariffById = 'getTariffByID',
  GetTariffList = 'getTariffList',
  GetTeamList = 'getTeamList',
  GetTemplateById = 'getTemplateByID',
  GetTemplateList = 'getTemplateList',
  GetUserList = 'getUserList',
  UpdateBankingPayment = 'updateBankingPayment',
  UpdateBankingProduct = 'updateBankingProduct',
  UpdateClient = 'updateClient',
  UpdateGame = 'updateGame',
  UpdateGameCategory = 'updateGameCategory',
  UpdateHitState = 'updateHitState',
  UpdateIntegrant = 'updateIntegrant',
  UpdateJackpot = 'updateJackpot',
  UpdateLocation = 'updateLocation',
  UpdatePayment = 'updatePayment',
  UpdateProductAccess = 'updateProductAccess',
  UpdateProductState = 'updateProductState',
  UpdateSystemKey = 'updateSystemKey',
  UpdateSystemKeyActivate = 'updateSystemKeyActivate',
  UpdateSystemKeyLock = 'updateSystemKeyLock',
  UpdateSystemState = 'updateSystemState',
  UpdateTariff = 'updateTariff',
  UpdateTeam = 'updateTeam',
  UpdateTemplate = 'updateTemplate',
  UpdateUser = 'updateUser',
}

export type AccessRightsPermission = {
  __typename?: 'AccessRightsPermission'
  isAllowed: Scalars['Boolean']
  permission: Scalars['String']
}

export type AccessRightsPermissionCodes = {
  __typename?: 'AccessRightsPermissionCodes'
  allowedAccessCodes: Array<Scalars['Int']>
  permission: Scalars['String']
}

export type Account = {
  __typename?: 'Account'
  currency?: Maybe<Currency>
  currencyId?: Maybe<Scalars['String']>
  currentBalance?: Maybe<Scalars['String']>
  id: Scalars['ID']
  inValue?: Maybe<Scalars['Int']>
  minBalance?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  outValue?: Maybe<Scalars['Int']>
}

export type Action = {
  __typename?: 'Action'
  id: Scalars['ID']
  name: Scalars['String']
  productType?: Maybe<Scalars['String']>
}

export type AddEntry = {
  jackpotID: Scalars['ID']
  name: Scalars['String']
}

export type Address = {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  house?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  index?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
}

export type Agreement = {
  __typename?: 'Agreement'
  clientID: Scalars['ID']
  coProduct?: Maybe<Product>
  coProductID?: Maybe<Scalars['ID']>
  consumer: Client
  creationDate: Scalars['String']
  currency?: Maybe<Currency>
  currencyID: Scalars['ID']
  id: Scalars['ID']
  product: Product
  productID: Scalars['ID']
  status: AgreementStatus
  tariff?: Maybe<Tariff>
  tariffID: Scalars['ID']
}

export enum AgreementStatus {
  Cancelled = 'cancelled',
  Created = 'created',
  Finished = 'finished',
  Running = 'running',
}

export type AgreementV = {
  __typename?: 'AgreementV'
  clientID: Scalars['ID']
  coProduct?: Maybe<Product>
  coProductID?: Maybe<Scalars['ID']>
  consumer?: Maybe<Client>
  id: Scalars['ID']
  name: Scalars['String']
  product: Product
  productID: Scalars['ID']
  provider?: Maybe<Client>
  state: ProductState
  status?: Maybe<AgreementStatus>
  type: Scalars['String']
}

export type Analytic = {
  __typename?: 'Analytic'
  createdAt: Scalars['String']
  id: Scalars['ID']
  parameters?: Maybe<AnalyticParameters>
  progress: Scalars['Float']
  state: AnalyticState
  type: AnalyticTypeName
}

export type AnalyticFilter = {
  __typename?: 'AnalyticFilter'
  name: Scalars['String']
  type: FilterType
  value?: Maybe<Array<Scalars['String']>>
}

export type AnalyticFilterInput = {
  name: AnalyticFilterName
  type: FilterType
  value?: InputMaybe<Array<Scalars['String']>>
}

export enum AnalyticFilterName {
  Action = 'action',
  Amount = 'amount',
  AmountInEur = 'amount_in_eur',
  Coproduct = 'coproduct',
  Currency = 'currency',
  Date = 'date',
  DerivedProductName = 'derived_product_name',
  PaidService = 'paid_service',
  Payee = 'payee',
  Payer = 'payer',
  ProviderName = 'provider_name',
  ProviderProductName = 'provider_product_name',
  ProviderProductType = 'provider_product_type',
}

export type AnalyticParameters = {
  __typename?: 'AnalyticParameters'
  filters?: Maybe<Array<Maybe<AnalyticFilter>>>
  sort?: Maybe<Scalars['String']>
  sortDirection?: Maybe<Scalars['String']>
}

export enum AnalyticState {
  Canceled = 'canceled',
  Failed = 'failed',
  Finished = 'finished',
  Pending = 'pending',
}

export type AnalyticType = {
  __typename?: 'AnalyticType'
  filters?: Maybe<Array<Maybe<Scalars['String']>>>
  name: Scalars['String']
  sortMethods?: Maybe<Array<Maybe<Scalars['String']>>>
}

export enum AnalyticTypeName {
  FeeGroupingByCoproduct = 'fee_grouping_by_coproduct',
  FeeGroupingCombined = 'fee_grouping_combined',
  Invoice = 'invoice',
  JackpotHostAppliedBets = 'jackpot_host_applied_bets',
  JackpotLevelAppliedBets = 'jackpot_level_applied_bets',
  Payments = 'payments',
  Transactions = 'transactions',
}

export type Analytics = {
  __typename?: 'Analytics'
  data?: Maybe<Array<Analytic>>
  meta?: Maybe<PaginationMeta>
}

export type Auth = {
  __typename?: 'Auth'
  accessToken: Scalars['String']
  actionRequired?: Maybe<RequiredActions>
  refreshToken: Scalars['String']
  tokenType?: Maybe<TokenType>
}

export type AuthResponse = {
  __typename?: 'AuthResponse'
  actionRequired?: Maybe<RequiredActions>
  tokenType?: Maybe<TokenType>
}

export type Available = {
  __typename?: 'Available'
  amount: Scalars['Float']
  currencyID: Scalars['ID']
}

export type BankingPayment = {
  __typename?: 'BankingPayment'
  actionID: Scalars['ID']
  agreement?: Maybe<Agreement>
  agreementID: Scalars['ID']
  clientID: Scalars['ID']
  details?: Maybe<Scalars['String']>
  id: Scalars['ID']
  invoice?: Maybe<Scalars['String']>
  invoiceDate?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['String']>
  orderDate?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  transaction?: Maybe<Transaction>
  transactionID: Scalars['ID']
}

export type BankingPaymentAgreementArgs = {
  clientID: Scalars['ID']
}

export type Bet = {
  __typename?: 'Bet'
  currency?: Maybe<Currency>
  denomination?: Maybe<Scalars['String']>
  gameEvent?: Maybe<GameEvent>
  gamePlace?: Maybe<GamePlace>
  id: Scalars['ID']
  lines?: Maybe<Scalars['Int']>
}

export type BoundConfig = IBoundConfig & {
  __typename?: 'BoundConfig'
  max?: Maybe<Config>
  min?: Maybe<Config>
  name?: Maybe<Scalars['String']>
}

export type ChangePasswordResponse = {
  __typename?: 'ChangePasswordResponse'
  isOk: Scalars['Boolean']
  totpRequired: Scalars['Boolean']
}

export type ChangePasswordResponse2Fa = {
  __typename?: 'ChangePasswordResponse2FA'
  isOk: Scalars['Boolean']
  totpRequired: Scalars['Boolean']
}

export type Client = {
  __typename?: 'Client'
  about?: Maybe<Scalars['String']>
  accounts?: Maybe<Array<Maybe<Account>>>
  address?: Maybe<Address>
  allowedCurrencies?: Maybe<Array<Maybe<Currency>>>
  allowedCurrencyIDs: Array<Scalars['ID']>
  contractDate: Scalars['String']
  contractID: Scalars['String']
  dataActions?: Maybe<ClientDataAction>
  email: Scalars['String']
  externalId: Scalars['ID']
  fullName: Scalars['String']
  id: Scalars['ID']
  integrant?: Maybe<Integrant>
  integrantID?: Maybe<Scalars['ID']>
  isBlocked?: Maybe<Scalars['Boolean']>
  jackpotSystems?: Maybe<Array<JackpotSystem>>
  license?: Maybe<Scalars['String']>
  maxUsersActiveSession?: Maybe<Scalars['Int']>
  maxUsersInactiveSession?: Maybe<Scalars['Int']>
  minBalances?: Maybe<Array<Maybe<MinBalance>>>
  name: Scalars['String']
  phone?: Maybe<Scalars['String']>
  role: ClientRole
  specializationIDs: Array<Scalars['String']>
  specializationList?: Maybe<Array<Scalars['String']>>
  specializations?: Maybe<Array<Specialization>>
  state?: Maybe<ClientState>
  systemCount?: Maybe<Scalars['Int']>
  teamCount?: Maybe<Scalars['Int']>
  userCount?: Maybe<Scalars['Int']>
  usersPasswordPeriod?: Maybe<Scalars['Int']>
}

export enum ClientAccountTypes {
  IncomeDeposits = 'income_deposits',
  IncomePrepayments = 'income_prepayments',
  InhouseClientClientCancelling = 'inhouse_client_clientCancelling',
  InhouseClientClientCurrent = 'inhouse_client_clientCurrent',
  InhouseClientClientPaying = 'inhouse_client_clientPaying',
  OutcomePrepaid = 'outcome_prepaid',
  OutcomeRepaid = 'outcome_repaid',
  OutcomeWithdrawn = 'outcome_withdrawn',
}

export type ClientDataAction = {
  __typename?: 'ClientDataAction'
  edit?: Maybe<Scalars['Boolean']>
  lock?: Maybe<Scalars['Boolean']>
  readAccounts?: Maybe<Scalars['Boolean']>
}

export enum ClientRole {
  Admin = 'admin',
  Jackpot = 'jackpot',
  Monitoring = 'monitoring',
  Promotion = 'promotion',
}

export enum ClientState {
  Active = 'active',
  Locked = 'locked',
}

export type Clients = {
  __typename?: 'Clients'
  data?: Maybe<Array<Maybe<Client>>>
  meta?: Maybe<PaginationMeta>
}

export type Config = IConfig & {
  __typename?: 'Config'
  defaultValue: Scalars['String']
  description: Scalars['String']
  id: Scalars['ID']
  isVisible?: Maybe<Scalars['Boolean']>
  max?: Maybe<Scalars['String']>
  min?: Maybe<Scalars['String']>
  name: Scalars['String']
  purpose?: Maybe<ConfigPurpose>
  type?: Maybe<ConfigType>
  valueType?: Maybe<ConfigValueType>
  values?: Maybe<Array<Scalars['String']>>
}

export enum ConfigPurpose {
  GameParam = 'GameParam',
  LevelBound = 'LevelBound',
  LevelParam = 'LevelParam',
  Unknown = 'Unknown',
}

export enum ConfigType {
  Any = 'any',
  Constant = 'constant',
  Range = 'range',
  Runtime = 'runtime',
  Values = 'values',
}

export enum ConfigValueType {
  Datetime = 'datetime',
  Integer = 'integer',
  Number = 'number',
  String = 'string',
}

export type CostOfService = {
  __typename?: 'CostOfService'
  amount: Scalars['Float']
  percent: Scalars['Float']
}

export type CreateJackpot = {
  agreement?: InputMaybe<UpsertJackpotAgreement>
  externalCode?: InputMaybe<Scalars['String']>
  fundsMode?: InputMaybe<JackpotFundsMode>
  hitAutoApprove?: InputMaybe<Scalars['Float']>
  jackpotSystemID: Scalars['ID']
  levels?: InputMaybe<Array<UpsertJackpotLevel>>
  logo?: InputMaybe<Scalars['String']>
  product: UpsertJackpotProduct
  reportDisableAsActive?: InputMaybe<Scalars['Boolean']>
  rng?: InputMaybe<Scalars['String']>
  runtime?: InputMaybe<Scalars['String']>
  templateID: Scalars['ID']
  tradeName?: InputMaybe<Scalars['String']>
  winMessage?: InputMaybe<Scalars['String']>
}

export type CreateTariff = {
  id?: InputMaybe<Scalars['ID']>
  isDefault?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  params?: InputMaybe<Array<InputMaybe<CreateTariffParam>>>
  productID?: InputMaybe<Scalars['String']>
  status?: InputMaybe<TariffStatus>
}

export type CreateTariffParam = {
  amount: Scalars['Float']
  percentage: Scalars['Float']
  serviceID: Scalars['ID']
}

export type CreateTemplate = {
  configs: Array<CreateTemplateConfig>
  product: CreateTemplateProduct
}

export type CreateTemplateConfig = {
  defaultValue: Scalars['String']
  description: Scalars['String']
  isVisible?: InputMaybe<Scalars['Boolean']>
  max?: InputMaybe<Scalars['String']>
  min?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  purpose?: InputMaybe<ConfigPurpose>
  type?: InputMaybe<ConfigType>
  valueType?: InputMaybe<ConfigValueType>
  values?: InputMaybe<Array<Scalars['String']>>
}

export type CreateTemplateProduct = {
  allowedCurrencyIDs?: InputMaybe<Array<Scalars['ID']>>
  baseCurrencyID: Scalars['ID']
  clientID: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  invoiceGenerationInterval?: InputMaybe<ProductInvoiceInterval>
  languageIDs?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  prepayment: Scalars['Float']
}

export type Currency = {
  __typename?: 'Currency'
  id: Scalars['ID']
  loadedAt: Scalars['String']
  name: Scalars['String']
  rate: Scalars['Float']
  rateToName: Scalars['String']
  scale: Scalars['Int']
}

export type CustomValue = {
  __typename?: 'CustomValue'
  amount: Scalars['Float']
  percent: Scalars['Float']
}

export type DefaultBoundConfig = IBoundConfig & {
  __typename?: 'DefaultBoundConfig'
  max?: Maybe<DefaultConfig>
  min?: Maybe<DefaultConfig>
  name?: Maybe<Scalars['String']>
}

export type DefaultConfig = IConfig & {
  __typename?: 'DefaultConfig'
  defaultValue: Scalars['String']
  description: Scalars['String']
  id?: Maybe<Scalars['ID']>
  isVisible?: Maybe<Scalars['Boolean']>
  max?: Maybe<Scalars['String']>
  min?: Maybe<Scalars['String']>
  name: Scalars['String']
  purpose?: Maybe<ConfigPurpose>
  type?: Maybe<ConfigType>
  valueType?: Maybe<ConfigValueType>
  values?: Maybe<Array<Scalars['String']>>
}

export type DefaultLevelConfig = ILevelConfig & {
  __typename?: 'DefaultLevelConfig'
  bounds?: Maybe<Array<DefaultBoundConfig>>
  index?: Maybe<Scalars['Int']>
  params?: Maybe<Array<DefaultConfig>>
}

export type DefaultTemplate = ITemplate & {
  __typename?: 'DefaultTemplate'
  gameParams?: Maybe<Array<DefaultConfig>>
  levelsConfig?: Maybe<Array<DefaultLevelConfig>>
}

export type Document = {
  __typename?: 'Document'
  data: Scalars['String']
}

export type Entry = {
  __typename?: 'Entry'
  id: Scalars['ID']
  jackpotID: Scalars['ID']
  name: Scalars['String']
  orders?: Maybe<Array<Maybe<EntryOrder>>>
}

export type EntryOrder = {
  __typename?: 'EntryOrder'
  clientName: Scalars['String']
  createdAt: Scalars['String']
  dataActions?: Maybe<EntryOrderDataActions>
  entryID: Scalars['ID']
  id: Scalars['ID']
  jackpot?: Maybe<Jackpot>
  jackpotID: Scalars['ID']
  status?: Maybe<OrderStatus>
  team?: Maybe<Team>
  teamID: Scalars['ID']
  teamName: Scalars['String']
}

export type EntryOrderDataActions = {
  __typename?: 'EntryOrderDataActions'
  lock?: Maybe<Scalars['Boolean']>
}

export type Filter = {
  __typename?: 'Filter'
  name: Scalars['String']
  type: FilterType
  value: Array<Maybe<Scalars['String']>>
}

export type FilterInput = {
  name: Scalars['String']
  type: FilterType
  value: Array<InputMaybe<Scalars['String']>>
}

export enum FilterType {
  And = 'and',
  Contains = 'contains',
  Has = 'has',
  Max = 'max',
  Min = 'min',
  Or = 'or',
  Range = 'range',
  Set = 'set',
  Value = 'value',
}

export enum FundType {
  Promotional = 'promotional',
  Real = 'real',
  Unknown = 'unknown',
}

export type Gambling = {
  __typename?: 'Gambling'
  amount?: Maybe<Scalars['Float']>
  client?: Maybe<Client>
  clientID: Scalars['ID']
  currency?: Maybe<Currency>
  currencyID: Scalars['ID']
  date?: Maybe<Scalars['String']>
  externalID: Scalars['ID']
  fundType?: Maybe<FundType>
  game?: Maybe<Game>
  gameID: Scalars['ID']
  id: Scalars['ID']
  location?: Maybe<Location>
  locationID: Scalars['ID']
  referredID?: Maybe<Scalars['ID']>
  registrationDate?: Maybe<Scalars['String']>
  roundID?: Maybe<Scalars['ID']>
  status?: Maybe<GamblingStatus>
  type?: Maybe<Scalars['String']>
}

export enum GamblingStatus {
  Done = 'done',
  Reversed = 'reversed',
}

export type Gamblings = {
  __typename?: 'Gamblings'
  data?: Maybe<Array<Maybe<Gambling>>>
  meta?: Maybe<PaginationMeta>
}

export type Game = {
  __typename?: 'Game'
  categories?: Maybe<Array<Maybe<GameCategory>>>
  categoryCount?: Maybe<Scalars['Int']>
  categoryIDs?: Maybe<Array<Maybe<Scalars['ID']>>>
  client?: Maybe<Client>
  clientID: Scalars['ID']
  clientName?: Maybe<Scalars['String']>
  details?: Maybe<Scalars['String']>
  externalID?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  vendor?: Maybe<Scalars['String']>
}

export type GameCategories = {
  __typename?: 'GameCategories'
  data?: Maybe<Array<Maybe<GameCategory>>>
  meta?: Maybe<PaginationMeta>
}

export type GameCategory = {
  __typename?: 'GameCategory'
  id: Scalars['ID']
  name: Scalars['String']
}

export type GameEvent = {
  __typename?: 'GameEvent'
  amount: Scalars['Int']
  id: Scalars['ID']
}

export type GamePlace = {
  __typename?: 'GamePlace'
  gameProgram?: Maybe<GameProgram>
  player?: Maybe<Player>
  playground?: Maybe<Playground>
}

export type GameProgram = {
  __typename?: 'GameProgram'
  name?: Maybe<Scalars['String']>
}

export type Games = {
  __typename?: 'Games'
  data?: Maybe<Array<Maybe<Game>>>
  meta?: Maybe<PaginationMeta>
}

export type Hit = {
  __typename?: 'Hit'
  amount: Scalars['Float']
  amountCurrency?: Maybe<Currency>
  amountCurrencyID: Scalars['ID']
  amountEventCurrency?: Maybe<Scalars['Float']>
  amountType: Scalars['String']
  awardWagerMultiplicator?: Maybe<Scalars['Int']>
  client?: Maybe<Client>
  clientName?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  eventID: Scalars['ID']
  expiredDate: Scalars['String']
  externalCongratulatorCode?: Maybe<Scalars['String']>
  externalID: Scalars['ID']
  gameBetAmount?: Maybe<Scalars['Float']>
  gameBetCurrency?: Maybe<Currency>
  gameBetCurrencyID: Scalars['ID']
  gameWinAmount?: Maybe<Scalars['Float']>
  hitApprover: HitApprover
  id: Scalars['ID']
  jackpot?: Maybe<Jackpot>
  jackpotID: Scalars['ID']
  jackpotName?: Maybe<Scalars['String']>
  levelCode?: Maybe<Scalars['String']>
  levelID: Scalars['ID']
  levelIteration?: Maybe<Scalars['Float']>
  levelName?: Maybe<Scalars['String']>
  location?: Maybe<Location>
  locationName?: Maybe<Scalars['String']>
  mostValuableParticipant?: Maybe<Scalars['String']>
  place: Place
  providerID: Scalars['ID']
  providerName?: Maybe<Scalars['String']>
  rank?: Maybe<Scalars['Int']>
  rankDistributionRule?: Maybe<Scalars['String']>
  rankHitShare?: Maybe<Scalars['Float']>
  rankLimit?: Maybe<Scalars['Float']>
  rankScope?: Maybe<Scalars['String']>
  scoreStrategy?: Maybe<Scalars['String']>
  state: HitState
  totalPrizes?: Maybe<Scalars['Float']>
  winMessage?: Maybe<Scalars['String']>
}

export type HitApprover = {
  __typename?: 'HitApprover'
  companyID?: Maybe<Scalars['String']>
  companyName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  verifiedAt?: Maybe<Scalars['String']>
}

export enum HitState {
  Approved = 'approved',
  Declined = 'declined',
  Expired = 'expired',
  Pending = 'pending',
}

export type Hits = {
  __typename?: 'Hits'
  data?: Maybe<Array<Hit>>
  meta?: Maybe<PaginationMeta>
}

export type IBoundConfig = {
  max?: Maybe<IConfig>
  min?: Maybe<IConfig>
  name?: Maybe<Scalars['String']>
}

export type IConfig = {
  defaultValue: Scalars['String']
  description: Scalars['String']
  id?: Maybe<Scalars['ID']>
  isVisible?: Maybe<Scalars['Boolean']>
  max?: Maybe<Scalars['String']>
  min?: Maybe<Scalars['String']>
  name: Scalars['String']
  purpose?: Maybe<ConfigPurpose>
  type?: Maybe<ConfigType>
  valueType?: Maybe<ConfigValueType>
  values?: Maybe<Array<Scalars['String']>>
}

export type ILevelConfig = {
  bounds?: Maybe<Array<IBoundConfig>>
  index?: Maybe<Scalars['Int']>
  params?: Maybe<Array<IConfig>>
}

export type ITemplate = {
  gameParams?: Maybe<Array<IConfig>>
  levelsConfig?: Maybe<Array<ILevelConfig>>
}

export type Integrant = {
  __typename?: 'Integrant'
  clientCount?: Maybe<Scalars['Int']>
  clientIds?: Maybe<Array<Maybe<Scalars['ID']>>>
  description?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  state?: Maybe<IntegrantState>
  type?: Maybe<Scalars['String']>
}

export enum IntegrantState {
  Active = 'active',
  Locked = 'locked',
}

export type Integrants = {
  __typename?: 'Integrants'
  data?: Maybe<Array<Integrant>>
  meta?: Maybe<PaginationMeta>
}

export type IntrospectionField = {
  __typename?: 'IntrospectionField'
  field: Scalars['String']
  filterTypes: Array<Maybe<FilterType>>
  filterValue: IntrospectionFilterValue
  sort: Scalars['Boolean']
}

export type IntrospectionFilterValue = {
  __typename?: 'IntrospectionFilterValue'
  optionsSrc?: Maybe<Scalars['String']>
  validations?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type IntrospectionQuery = {
  __typename?: 'IntrospectionQuery'
  endpoint: Scalars['String']
  fields: Array<Maybe<IntrospectionField>>
}

export type IsClientExistInBanking = {
  __typename?: 'IsClientExistInBanking'
  exists?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
}

export type IsssOkResponse = {
  __typename?: 'IsssOKResponse'
  isOk: Scalars['Boolean']
}

export type Jackpot = {
  __typename?: 'Jackpot'
  agreement?: Maybe<Agreement>
  agreementID: Scalars['ID']
  entries?: Maybe<Array<Maybe<Entry>>>
  entryCount?: Maybe<Scalars['Int']>
  externalCode?: Maybe<Scalars['String']>
  externalID: Scalars['ID']
  fundsMode?: Maybe<JackpotFundsMode>
  hitAutoApprove?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  jackpotSystemID?: Maybe<Scalars['ID']>
  levels?: Maybe<Array<Maybe<Level>>>
  levelsCount?: Maybe<Scalars['Int']>
  logo?: Maybe<Scalars['String']>
  product: Product
  productID: Scalars['ID']
  reportDisableAsActive?: Maybe<Scalars['Boolean']>
  rng?: Maybe<Scalars['String']>
  runtime?: Maybe<Scalars['String']>
  scheduler?: Maybe<JackpotScheduler>
  teamsCount?: Maybe<Scalars['Int']>
  template?: Maybe<Template>
  templateID: Scalars['ID']
  tradeName?: Maybe<Scalars['String']>
  winMessage?: Maybe<Scalars['String']>
}

export type JackpotAgreementArgs = {
  clientID: Scalars['ID']
}

export type JackpotTeamsCountArgs = {
  clientID: Scalars['ID']
}

export enum JackpotFundsMode {
  Original = 'original',
  Test = 'test',
  Virtual = 'virtual',
}

export type JackpotScheduler = {
  __typename?: 'JackpotScheduler'
  activitySpans: Array<Maybe<JackpotSchedulerSpan>>
  lastStateUpdateTrigger: JackpotSchedulerLastState
  spansEndAt?: Maybe<Scalars['String']>
  spansStartAt?: Maybe<Scalars['String']>
  state: JackpotSchedulerState
}

export enum JackpotSchedulerLastState {
  Manual = 'manual',
  System = 'system',
}

export type JackpotSchedulerLevelHitStats = {
  __typename?: 'JackpotSchedulerLevelHitStats'
  activeHits: Scalars['Float']
  levelID: Scalars['ID']
  totalHits: Scalars['Float']
}

export type JackpotSchedulerSpan = {
  __typename?: 'JackpotSchedulerSpan'
  calendarColor?: Maybe<Scalars['String']>
  endSec: Scalars['Int']
  id: Scalars['ID']
  levelHitLimits?: Maybe<Array<Maybe<JackpotSchedulerSpanLevelHitLimits>>>
  levelHitStats?: Maybe<Array<Maybe<JackpotSchedulerLevelHitStats>>>
  startSec: Scalars['Int']
}

export type JackpotSchedulerSpanLevelHitLimits = {
  __typename?: 'JackpotSchedulerSpanLevelHitLimits'
  hitLimit: Scalars['Float']
  levelID: Scalars['ID']
}

export enum JackpotSchedulerState {
  Active = 'active',
  Inactive = 'inactive',
}

export type JackpotSystem = {
  __typename?: 'JackpotSystem'
  clientID: Scalars['ID']
  id: Scalars['ID']
  name: Scalars['String']
}

export type Jackpots = {
  __typename?: 'Jackpots'
  data?: Maybe<Array<Maybe<Jackpot>>>
  meta?: Maybe<PaginationMeta>
}

export type Language = {
  __typename?: 'Language'
  id: Scalars['ID']
  name: Scalars['String']
}

export type Level = {
  __typename?: 'Level'
  activationBets?: Maybe<Scalars['Float']>
  activationGames?: Maybe<Scalars['Float']>
  activationTime?: Maybe<Scalars['Float']>
  activityBreak?: Maybe<Scalars['Float']>
  awardBase?: Maybe<Scalars['String']>
  awardBaseMaxDrawTime?: Maybe<Scalars['Float']>
  awardBaseMaxTotalBet?: Maybe<Scalars['Float']>
  awardBaseMinDrawTime?: Maybe<Scalars['Float']>
  awardBaseMinTotalBet?: Maybe<Scalars['Float']>
  awardLimits?: Maybe<Array<Maybe<LevelAwardLimit>>>
  awardServe?: Maybe<Scalars['String']>
  awardType?: Maybe<LevelAwardType>
  awardValue?: Maybe<Scalars['Float']>
  awardWagerMultiplicator?: Maybe<Scalars['Float']>
  clientName?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['Float']>
  contribution?: Maybe<LevelContributionType>
  currentValue?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
  eventAmountType?: Maybe<Scalars['String']>
  eventType?: Maybe<Scalars['String']>
  externalCode?: Maybe<Scalars['String']>
  externalCongratulatorCode?: Maybe<Scalars['String']>
  fundBase?: Maybe<Scalars['Float']>
  fundIncrement?: Maybe<Scalars['Float']>
  fundIncrementMethod?: Maybe<Scalars['String']>
  fundLimit?: Maybe<Scalars['Float']>
  fundOverflowIncrement?: Maybe<Scalars['Float']>
  fundOverflowIncrementMethod?: Maybe<Scalars['String']>
  fundReserveIncrement?: Maybe<Scalars['Float']>
  fundReserveIncrementMethod?: Maybe<Scalars['String']>
  fundResetOverflow?: Maybe<Scalars['Float']>
  fundResetReserve?: Maybe<Scalars['Float']>
  fundResetValue?: Maybe<Scalars['Float']>
  fundSecondaryIncrement?: Maybe<Scalars['Float']>
  fundSecondaryIncrementMethod?: Maybe<Scalars['String']>
  hitAutoApprove?: Maybe<Scalars['Float']>
  hits?: Maybe<Scalars['Float']>
  hitsCount?: Maybe<Scalars['Float']>
  id: Scalars['ID']
  index: Scalars['Int']
  jackpotID?: Maybe<Scalars['ID']>
  levelMeters?: Maybe<LevelMeters>
  loseSession?: Maybe<Scalars['Int']>
  manualHitDistribution?: Maybe<Array<Maybe<Scalars['Float']>>>
  maxBet?: Maybe<Scalars['Float']>
  minBet?: Maybe<Scalars['Float']>
  mostValuableParticipant?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  participantType?: Maybe<Scalars['String']>
  period?: Maybe<Scalars['Int']>
  product?: Maybe<Product>
  rankDistributionRule?: Maybe<Scalars['String']>
  rankHitShare?: Maybe<Scalars['Float']>
  rankLimit?: Maybe<Scalars['Float']>
  rankScope?: Maybe<Scalars['String']>
  runtime?: Maybe<Scalars['String']>
  scoreStrategy?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  state?: Maybe<LevelStateType>
  winRate?: Maybe<Scalars['Int']>
  winSession?: Maybe<Scalars['Int']>
}

export type LevelAwardLimit = {
  __typename?: 'LevelAwardLimit'
  code: Scalars['String']
  id?: Maybe<Scalars['ID']>
  value?: Maybe<Scalars['Float']>
}

export enum LevelAwardSetupType {
  Manually = 'manually',
  System = 'system',
  Test = 'test',
}

export enum LevelAwardType {
  Achievement = 'achievement',
  Chance = 'chance',
  Mystery = 'mystery',
  Test = 'test',
  Tournament = 'tournament',
}

export type LevelConfig = ILevelConfig & {
  __typename?: 'LevelConfig'
  bounds?: Maybe<Array<BoundConfig>>
  index?: Maybe<Scalars['Int']>
  params?: Maybe<Array<Config>>
}

export enum LevelContributionType {
  Soft = 'soft',
  Strict = 'strict',
  Test = 'test',
}

export type LevelMeters = {
  __typename?: 'LevelMeters'
  canceledIncrements?: Maybe<Scalars['Money']>
  current?: Maybe<Scalars['Money']>
  currentTime?: Maybe<Scalars['Int']>
  hits?: Maybe<Scalars['Int']>
  overflow?: Maybe<Scalars['Money']>
  recoveredWagers?: Maybe<Scalars['Money']>
  rejectedIncrements?: Maybe<Scalars['Money']>
  rejectedWagers?: Maybe<Scalars['Money']>
  rejectionProgress?: Maybe<Scalars['Money']>
  reserve?: Maybe<Scalars['Money']>
  resetValueAmount?: Maybe<Scalars['Money']>
  totalAwarded?: Maybe<Scalars['Money']>
  totalIncremented?: Maybe<Scalars['Money']>
  totalWagered?: Maybe<Scalars['Money']>
}

export enum LevelStateType {
  Active = 'active',
  Created = 'created',
  Disabled = 'disabled',
  Finished = 'finished',
}

export type Levels = {
  __typename?: 'Levels'
  data?: Maybe<Array<Maybe<Level>>>
  meta?: Maybe<PaginationMeta>
}

export type Location = {
  __typename?: 'Location'
  address?: Maybe<LocationAddress>
  client?: Maybe<Client>
  clientID: Scalars['ID']
  details?: Maybe<Scalars['String']>
  externalID?: Maybe<Scalars['String']>
  id: Scalars['ID']
  integrant?: Maybe<Integrant>
  name: Scalars['String']
  phone?: Maybe<Scalars['String']>
  type?: Maybe<LocationType>
  typeID: Scalars['ID']
  url?: Maybe<Scalars['String']>
}

export type LocationAddress = {
  __typename?: 'LocationAddress'
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  house?: Maybe<Scalars['String']>
  region?: Maybe<Scalars['String']>
  street?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type LocationType = {
  __typename?: 'LocationType'
  id: Scalars['ID']
  name: Scalars['String']
}

export type Locations = {
  __typename?: 'Locations'
  data?: Maybe<Array<Maybe<Location>>>
  meta?: Maybe<PaginationMeta>
}

export type Log = {
  __typename?: 'Log'
  changes: Scalars['String']
  client: Client
  dateTime: Scalars['String']
  id: Scalars['ID']
  level?: Maybe<Level>
  transaction?: Maybe<Transaction>
  type: Scalars['String']
  user: User
}

export type Logs = {
  __typename?: 'Logs'
  data?: Maybe<Array<Maybe<Log>>>
  meta?: Maybe<PaginationMeta>
}

export type MinBalance = {
  __typename?: 'MinBalance'
  currency?: Maybe<Currency>
  value?: Maybe<Scalars['Float']>
}

export type Mutation = {
  __typename?: 'Mutation'
  activateTariff?: Maybe<Tariff>
  addEntry?: Maybe<Entry>
  addOrder?: Maybe<EntryOrder>
  addOrders?: Maybe<Array<Maybe<Entry>>>
  applyRegistration?: Maybe<RegistrationResponse>
  assignTariff?: Maybe<Product>
  cancelReport: Analytic
  changePassword?: Maybe<ChangePasswordResponse>
  changePassword2Fa?: Maybe<ChangePasswordResponse2Fa>
  copyTeam?: Maybe<Team>
  create2faSecret?: Maybe<Secret2fa>
  createJackpot?: Maybe<Jackpot>
  createReport: Analytic
  createTemplate?: Maybe<Template>
  login?: Maybe<AuthResponse>
  login2fa?: Maybe<User>
  logout?: Maybe<Scalars['String']>
  refreshToken?: Maybe<Auth>
  remove2fa?: Maybe<Remove2faResponse>
  removeMy2fa?: Maybe<Remove2faResponse>
  resetPasswordAuth?: Maybe<ResetPasswordResponse>
  resetPasswordNoAuth?: Maybe<ResetPasswordResponse>
  setNewPassword?: Maybe<SetNewPasswordResponse>
  setNewPassword2Fa?: Maybe<SetNewPasswordResponse>
  setUserState?: Maybe<User>
  toggleLockClient?: Maybe<Client>
  updateHitState?: Maybe<IsssOkResponse>
  updateIntegrant?: Maybe<Integrant>
  updateIntegrantState?: Maybe<Integrant>
  updateJackpot?: Maybe<Jackpot>
  updateJackpotScheduler?: Maybe<Jackpot>
  updateJackpotState?: Maybe<Product>
  updateOrderStatus?: Maybe<EntryOrder>
  updateProductAccess?: Maybe<Product>
  updateProductState?: Maybe<Product>
  updatePureJackpot?: Maybe<Jackpot>
  updateSystemKey: System
  updateSystemState: System
  updateTariff?: Maybe<Tariff>
  updateTemplate?: Maybe<Template>
  updateTemplateAccessByProductID?: Maybe<Template>
  updateTemplateState?: Maybe<Product>
  upsertBankingPayment?: Maybe<PaymentId>
  upsertBankingProduct?: Maybe<Product>
  upsertClient?: Maybe<Client>
  upsertLocation?: Maybe<Location>
  upsertTariff?: Maybe<Tariff>
  upsertTeam?: Maybe<Team>
  upsertUser?: Maybe<User>
}

export type MutationActivateTariffArgs = {
  id: Scalars['ID']
  status: TariffStatus
}

export type MutationAddEntryArgs = {
  entry?: InputMaybe<AddEntry>
}

export type MutationAddOrderArgs = {
  entryID: Scalars['ID']
  teamID: Scalars['ID']
}

export type MutationAddOrdersArgs = {
  entryID: Scalars['ID']
  teamIDs: Array<Scalars['ID']>
}

export type MutationApplyRegistrationArgs = {
  confirmPassword: Scalars['String']
  password: Scalars['String']
  token: Scalars['String']
}

export type MutationAssignTariffArgs = {
  id: Scalars['ID']
  tariffID: Scalars['ID']
}

export type MutationCancelReportArgs = {
  id: Scalars['ID']
}

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
}

export type MutationChangePassword2FaArgs = {
  code: Scalars['String']
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
}

export type MutationCopyTeamArgs = {
  team: UpsertTeam
}

export type MutationCreate2faSecretArgs = {
  _?: InputMaybe<Scalars['String']>
}

export type MutationCreateJackpotArgs = {
  entries: Array<InputMaybe<UpsertJackpotEntry>>
  jackpot: CreateJackpot
}

export type MutationCreateReportArgs = {
  analytic?: InputMaybe<UpsertAnalytic>
}

export type MutationCreateTemplateArgs = {
  template: CreateTemplate
}

export type MutationLoginArgs = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MutationLogin2faArgs = {
  code: Scalars['String']
}

export type MutationLogoutArgs = {
  _?: InputMaybe<Scalars['String']>
}

export type MutationRefreshTokenArgs = {
  _?: InputMaybe<Scalars['String']>
}

export type MutationRemove2faArgs = {
  id: Scalars['ID']
}

export type MutationRemoveMy2faArgs = {
  code: Scalars['String']
}

export type MutationResetPasswordNoAuthArgs = {
  email: Scalars['String']
}

export type MutationSetNewPasswordArgs = {
  confirmPassword: Scalars['String']
  password: Scalars['String']
  token: Scalars['String']
}

export type MutationSetNewPassword2FaArgs = {
  code: Scalars['String']
  confirmPassword: Scalars['String']
  password: Scalars['String']
  token: Scalars['String']
}

export type MutationSetUserStateArgs = {
  id: Scalars['ID']
  state: UserState
}

export type MutationToggleLockClientArgs = {
  id?: InputMaybe<Scalars['ID']>
  isBlocked?: InputMaybe<Scalars['Boolean']>
}

export type MutationUpdateHitStateArgs = {
  id: Scalars['ID']
  state: HitState
}

export type MutationUpdateIntegrantArgs = {
  integrant?: InputMaybe<UpdateIntegrant>
}

export type MutationUpdateIntegrantStateArgs = {
  id?: InputMaybe<Scalars['ID']>
  state?: InputMaybe<IntegrantState>
}

export type MutationUpdateJackpotArgs = {
  entries: Array<InputMaybe<UpsertJackpotEntry>>
  jackpot: UpdateJackpot
}

export type MutationUpdateJackpotSchedulerArgs = {
  jackpotID: Scalars['ID']
  productID: Scalars['ID']
  scheduler: UpsertJackpotScheduler
}

export type MutationUpdateJackpotStateArgs = {
  clientID: Scalars['ID']
  jackpotID: Scalars['ID']
  state: ProductState
}

export type MutationUpdateOrderStatusArgs = {
  id: Scalars['ID']
  status?: InputMaybe<OrderStatus>
}

export type MutationUpdateProductAccessArgs = {
  access: UpdateProductAccess
  id: Scalars['ID']
}

export type MutationUpdateProductStateArgs = {
  clientID: Scalars['ID']
  id: Scalars['ID']
  state?: InputMaybe<ProductState>
}

export type MutationUpdatePureJackpotArgs = {
  jackpot: UpdateJackpot
}

export type MutationUpdateSystemKeyArgs = {
  keyFactor: SystemKeyFactor
  publicKey: Scalars['String']
  systemID: Scalars['ID']
}

export type MutationUpdateSystemStateArgs = {
  action: UpdateSystemStateAction
  systemID: Scalars['ID']
}

export type MutationUpdateTariffArgs = {
  tariff?: InputMaybe<UpdateTariff>
}

export type MutationUpdateTemplateArgs = {
  id: Scalars['ID']
  template: UpdateTemplate
}

export type MutationUpdateTemplateAccessByProductIdArgs = {
  access: UpdateProductAccess
  id: Scalars['ID']
}

export type MutationUpdateTemplateStateArgs = {
  clientID: Scalars['ID']
  productID: Scalars['ID']
  state: ProductState
}

export type MutationUpsertBankingPaymentArgs = {
  bankingPayment?: InputMaybe<UpsertBankingPayment>
}

export type MutationUpsertBankingProductArgs = {
  id?: InputMaybe<Scalars['ID']>
  product: UpdateProduct
}

export type MutationUpsertClientArgs = {
  client?: InputMaybe<UpsertClient>
  integrantID?: InputMaybe<Scalars['ID']>
}

export type MutationUpsertLocationArgs = {
  location?: InputMaybe<UpsertLocation>
}

export type MutationUpsertTariffArgs = {
  tariff?: InputMaybe<CreateTariff>
}

export type MutationUpsertTeamArgs = {
  team: UpsertTeam
}

export type MutationUpsertUserArgs = {
  user: UpsertUser
}

export enum OrderStatus {
  Approved = 'approved',
  Canceled = 'canceled',
  Finished = 'finished',
  Pending = 'pending',
  Rejected = 'rejected',
}

export type PaginationMeta = {
  __typename?: 'PaginationMeta'
  offset: Scalars['Int']
  totalCount: Scalars['Int']
}

export type Payment = {
  __typename?: 'Payment'
  actionID: Scalars['ID']
  agreement?: Maybe<Agreement>
  agreementID: Scalars['ID']
  details?: Maybe<Scalars['String']>
  id: Scalars['ID']
  payee?: Maybe<Client>
  payeeClientID: Scalars['ID']
  payer?: Maybe<Client>
  payerClientID: Scalars['ID']
  state?: Maybe<Scalars['String']>
  transaction?: Maybe<Transaction>
  transactionID: Scalars['ID']
}

export type PaymentAgreementArgs = {
  clientID: Scalars['ID']
}

export type PaymentId = {
  __typename?: 'PaymentID'
  id: Scalars['ID']
}

export enum PaymentState {
  Declined = 'declined',
  Finished = 'finished',
  Invalid = 'invalid',
  Pending = 'pending',
}

export type Payments = {
  __typename?: 'Payments'
  data?: Maybe<Array<Maybe<Payment>>>
  meta?: Maybe<PaginationMeta>
}

export type Permission = {
  __typename?: 'Permission'
  id: Scalars['Int']
  name: Scalars['String']
}

export type Place = {
  __typename?: 'Place'
  clientID: Scalars['String']
  externalID: Scalars['String']
  externalLocationID: Scalars['String']
  game: Game
  id: Scalars['ID']
  locationID: Scalars['String']
  player: Player
}

export type Player = {
  __typename?: 'Player'
  clientID?: Maybe<Scalars['String']>
  externalID: Scalars['String']
  id: Scalars['ID']
  nickname?: Maybe<Scalars['String']>
  statuses?: Maybe<Array<Scalars['String']>>
}

export type Players = {
  __typename?: 'Players'
  data?: Maybe<Array<Player>>
  meta?: Maybe<PaginationMeta>
}

export type Playground = {
  __typename?: 'Playground'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
}

export type Product = {
  __typename?: 'Product'
  access?: Maybe<ProductAccess>
  accessClientsExclude?: Maybe<Array<Maybe<Client>>>
  accessClientsInclude?: Maybe<Array<Maybe<Client>>>
  allowedCurrencyIDs: Array<Maybe<Scalars['ID']>>
  baseCurrency?: Maybe<Currency>
  baseCurrencyID: Scalars['ID']
  clientID: Scalars['ID']
  creationDate: Scalars['String']
  currencies?: Maybe<Array<Maybe<Currency>>>
  dataActions?: Maybe<ProductDataActions>
  description: Scalars['String']
  id: Scalars['ID']
  invoiceGenerationInterval?: Maybe<ProductInvoiceInterval>
  languageIDs: Array<Maybe<Scalars['ID']>>
  name: Scalars['String']
  prepayment: Scalars['Float']
  provider?: Maybe<Client>
  state: ProductState
  tariffs?: Maybe<Array<Maybe<Tariff>>>
  type: Scalars['String']
}

export type ProductTariffsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
}

export enum ProductAccess {
  Private = 'private',
  Public = 'public',
}

export type ProductAndService = AgreementV | ProductV

export type ProductDataActions = {
  __typename?: 'ProductDataActions'
  lock: Scalars['Boolean']
  readAccess: Scalars['Boolean']
  readOnly: Scalars['Boolean']
  update: Scalars['Boolean']
  updateAccess: Scalars['Boolean']
  updateAccessList: Scalars['Boolean']
}

export enum ProductInvoiceInterval {
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Month = 'month',
  Week = 'week',
}

export enum ProductServiceType {
  Demand = 'demand',
  Supply = 'supply',
}

export enum ProductState {
  Active = 'active',
  Created = 'created',
  Disabled = 'disabled',
  Finished = 'finished',
  Paused = 'paused',
}

export enum ProductType {
  Banking = 'banking',
  JackpotOperating = 'jackpotOperating',
  JackpotOwnership = 'jackpotOwnership',
}

export type ProductV = {
  __typename?: 'ProductV'
  clientID: Scalars['ID']
  consumer?: Maybe<Client>
  id: Scalars['ID']
  name: Scalars['String']
  provider?: Maybe<Client>
  state: ProductState
  type: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  About?: Maybe<About>
  Actions?: Maybe<Array<Action>>
  Agreement?: Maybe<Agreement>
  AgreementProductsByAllClients?: Maybe<Array<Agreement>>
  Agreements?: Maybe<Array<Maybe<Agreement>>>
  AllClientsJackpots?: Maybe<Array<Maybe<Jackpot>>>
  AllIntegrants?: Maybe<Array<Maybe<Integrant>>>
  Analytic?: Maybe<Analytic>
  AnalyticRecentTypes: Array<AnalyticType>
  /** @deprecated Don't use graphql mutation for file download: use direct download instead */
  AnalyticReport: Document
  AnalyticTypes: Array<AnalyticType>
  Analytics?: Maybe<Analytics>
  BankingAgreementByClientID?: Maybe<Agreement>
  BankingPayment?: Maybe<BankingPayment>
  Client?: Maybe<Client>
  ClientIntegrant?: Maybe<Integrant>
  ClientTeams?: Maybe<Array<Maybe<Team>>>
  Clients?: Maybe<Clients>
  Currencies?: Maybe<Array<Currency>>
  DefaultTemplate?: Maybe<DefaultTemplate>
  Gamblings?: Maybe<Gamblings>
  Game?: Maybe<Game>
  GameCategories?: Maybe<GameCategories>
  Games?: Maybe<Games>
  Hit?: Maybe<Hit>
  HitSubscriptions?: Maybe<Scalars['String']>
  Hits?: Maybe<Hits>
  Integrant?: Maybe<Integrant>
  Integrants?: Maybe<Integrants>
  Introspection?: Maybe<Array<Maybe<IntrospectionQuery>>>
  IsClientExistsInBanking?: Maybe<Array<Maybe<IsClientExistInBanking>>>
  Jackpot?: Maybe<Jackpot>
  JackpotAvailableTemplates?: Maybe<Array<Maybe<Template>>>
  JackpotEntries?: Maybe<Array<Maybe<Entry>>>
  JackpotSubscriptions?: Maybe<Scalars['String']>
  Jackpots?: Maybe<Jackpots>
  Languages?: Maybe<Array<Maybe<Language>>>
  Levels?: Maybe<Levels>
  Location?: Maybe<Location>
  LocationTypes?: Maybe<Array<Maybe<LocationType>>>
  Locations?: Maybe<Locations>
  Log?: Maybe<Log>
  Logs?: Maybe<Logs>
  MyActiveBankingProduct?: Maybe<Product>
  Payments?: Maybe<Payments>
  Player?: Maybe<Player>
  Players?: Maybe<Players>
  Product?: Maybe<Product>
  Products?: Maybe<Array<Maybe<Product>>>
  ProductsAndServices?: Maybe<Array<Maybe<ProductAndService>>>
  ServiceInvoice?: Maybe<ServiceInvoice>
  ServiceInvoices?: Maybe<ServiceInvoices>
  Services?: Maybe<Array<Maybe<Service>>>
  Specializations?: Maybe<Array<Maybe<Specialization>>>
  System?: Maybe<System>
  Systems?: Maybe<Systems>
  Team?: Maybe<Team>
  TeamSubscriptions?: Maybe<Scalars['String']>
  Teams?: Maybe<Teams>
  Template?: Maybe<Template>
  TemplateProductByID?: Maybe<Template>
  Templates?: Maybe<Templates>
  User?: Maybe<User>
  Users?: Maybe<Users>
  controlledClients?: Maybe<Array<Client>>
  dirtyFilters?: Maybe<Scalars['Boolean']>
  my?: Maybe<User>
  resetFilters?: Maybe<Scalars['Boolean']>
  validateRegistrationToken?: Maybe<ValidateTokenResponse>
  validateResetPasswordToken?: Maybe<ValidateTokenResponse>
}

export type QueryAgreementArgs = {
  id: Scalars['ID']
}

export type QueryAgreementProductsByAllClientsArgs = {
  filters?: InputMaybe<Array<FilterInput>>
}

export type QueryAgreementsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type QueryAllClientsJackpotsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
}

export type QueryAnalyticArgs = {
  id: Scalars['ID']
}

export type QueryAnalyticReportArgs = {
  id: Scalars['ID']
  type: Scalars['String']
}

export type QueryAnalyticsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type QueryBankingAgreementByClientIdArgs = {
  clientID: Scalars['String']
}

export type QueryBankingPaymentArgs = {
  id: Scalars['ID']
}

export type QueryClientArgs = {
  id: Scalars['ID']
}

export type QueryClientIntegrantArgs = {
  id: Scalars['ID']
}

export type QueryClientTeamsArgs = {
  filters?: InputMaybe<Array<FilterInput>>
}

export type QueryClientsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
}

export type QueryCurrenciesArgs = {
  search?: InputMaybe<Scalars['String']>
}

export type QueryGamblingsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type QueryGameArgs = {
  id: Scalars['ID']
}

export type QueryGameCategoriesArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit?: InputMaybe<Scalars['Int']>
  offset: Scalars['Int']
}

export type QueryGamesArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
}

export type QueryHitArgs = {
  id: Scalars['ID']
}

export type QueryHitsArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
}

export type QueryIntegrantArgs = {
  id: Scalars['ID']
}

export type QueryIntegrantsArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
}

export type QueryIntrospectionArgs = {
  endpoints?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type QueryIsClientExistsInBankingArgs = {
  ids: Array<Scalars['ID']>
}

export type QueryJackpotArgs = {
  id: Scalars['ID']
}

export type QueryJackpotAvailableTemplatesArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type QueryJackpotEntriesArgs = {
  jackpotID: Scalars['ID']
}

export type QueryJackpotsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
}

export type QueryLevelsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
}

export type QueryLocationArgs = {
  id: Scalars['ID']
}

export type QueryLocationsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
}

export type QueryLogArgs = {
  id: Scalars['ID']
}

export type QueryLogsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type QueryPaymentsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type QueryPlayerArgs = {
  id: Scalars['ID']
}

export type QueryPlayersArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type QueryProductArgs = {
  id: Scalars['ID']
}

export type QueryProductsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type QueryProductsAndServicesArgs = {
  filters: Array<InputMaybe<FilterInput>>
  limit: Scalars['Int']
  offset: Scalars['Int']
}

export type QueryServiceInvoiceArgs = {
  id: Scalars['ID']
}

export type QueryServiceInvoicesArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
}

export type QueryServicesArgs = {
  productType?: InputMaybe<Scalars['String']>
}

export type QuerySystemArgs = {
  id: Scalars['ID']
}

export type QuerySystemsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
}

export type QueryTeamArgs = {
  id: Scalars['ID']
}

export type QueryTeamsArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
}

export type QueryTemplateArgs = {
  id: Scalars['ID']
}

export type QueryTemplateProductByIdArgs = {
  productID: Scalars['ID']
}

export type QueryTemplatesArgs = {
  filters?: InputMaybe<Array<FilterInput>>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
}

export type QueryUserArgs = {
  id: Scalars['ID']
}

export type QueryUsersArgs = {
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>
  limit: Scalars['Int']
  offset: Scalars['Int']
  sortBy?: InputMaybe<Scalars['String']>
  sortOrder?: InputMaybe<SortOrder>
}

export type QueryValidateRegistrationTokenArgs = {
  token: Scalars['String']
}

export type QueryValidateResetPasswordTokenArgs = {
  token: Scalars['String']
}

export type RegistrationResponse = {
  __typename?: 'RegistrationResponse'
  isOk: Scalars['Boolean']
}

export type Remove2faResponse = {
  __typename?: 'Remove2faResponse'
  result?: Maybe<Scalars['String']>
}

export type RequestPermissions = {
  __typename?: 'RequestPermissions'
  method: Scalars['String']
  path: Scalars['String']
  permissions: Array<Permission>
}

export enum RequiredActions {
  ConnectMfa = 'connect_mfa',
  Mfa = 'mfa',
}

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse'
  isMailSendDisabled?: Maybe<Scalars['Boolean']>
  token?: Maybe<Scalars['String']>
}

export enum RestMethods {
  Delete = 'DELETE',
  Get = 'GET',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT',
}

export type Secret2fa = {
  __typename?: 'Secret2fa'
  secret: Scalars['String']
  totpUri: Scalars['String']
}

export type Service = {
  __typename?: 'Service'
  actionID: Scalars['ID']
  id: Scalars['ID']
  name: Scalars['String']
  productType: Scalars['String']
}

export type ServiceInvoice = {
  __typename?: 'ServiceInvoice'
  agreementCurrency: Currency
  agreementCurrencyRate: Scalars['Float']
  agreementId: Scalars['ID']
  approvedAmount: Scalars['Float']
  calculatedFeeInAgreementCurrency: Scalars['Float']
  comment?: Maybe<Scalars['String']>
  consumerId: Scalars['ID']
  consumerName: Scalars['String']
  coproductId: Scalars['ID']
  coproductName: Scalars['String']
  costOfService: CostOfService
  createdAt: Scalars['String']
  currencyName: Scalars['String']
  customSetting?: Maybe<Scalars['String']>
  customValue?: Maybe<CustomValue>
  id: Scalars['ID']
  invoicePeriod: ServiceInvoicePeriod
  jackpotId?: Maybe<Scalars['ID']>
  productId: Scalars['ID']
  productName: Scalars['String']
  providerId: Scalars['ID']
  providerName: Scalars['String']
  revision: Scalars['String']
  serviceName: Scalars['String']
  state: ServiceInvoiceState
  tariffName: Scalars['String']
  userId: Scalars['ID']
}

export type ServiceInvoicePeriod = {
  __typename?: 'ServiceInvoicePeriod'
  endDate: Scalars['String']
  startDate: Scalars['String']
}

export enum ServiceInvoiceState {
  Approved = 'approved',
  Completed = 'completed',
  Pending = 'pending',
}

export type ServiceInvoices = {
  __typename?: 'ServiceInvoices'
  data?: Maybe<Array<Maybe<ServiceInvoice>>>
  meta?: Maybe<PaginationMeta>
}

export type SetNewPasswordResponse = {
  __typename?: 'SetNewPasswordResponse'
  isOk: Scalars['Boolean']
  totpRequired: Scalars['Boolean']
}

export type SetNewPasswordResponse2Fa = {
  __typename?: 'SetNewPasswordResponse2FA'
  isOk: Scalars['Boolean']
  totpRequired: Scalars['Boolean']
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type Specialization = {
  __typename?: 'Specialization'
  id: Scalars['ID']
  label?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type Subscribe = {
  __typename?: 'Subscribe'
  enabled?: Maybe<Scalars['Boolean']>
  topic: Scalars['String']
  url?: Maybe<Scalars['String']>
}

export type System = {
  __typename?: 'System'
  client?: Maybe<Client>
  clientID: Scalars['ID']
  createdAt?: Maybe<Scalars['String']>
  dataActions?: Maybe<SystemDataAction>
  hitSubscriptions?: Maybe<Subscribe>
  id: Scalars['ID']
  jackpotSubscriptions?: Maybe<Subscribe>
  keys?: Maybe<Array<Maybe<SystemKey>>>
  name: Scalars['String']
  state?: Maybe<SystemState>
  teamSubscriptions?: Maybe<Subscribe>
}

export type SystemDataAction = {
  __typename?: 'SystemDataAction'
  edit: Scalars['Boolean']
  lock: Scalars['Boolean']
}

export type SystemKey = {
  __typename?: 'SystemKey'
  id: Scalars['ID']
  isBlocked?: Maybe<Scalars['Boolean']>
  keyFactor?: Maybe<SystemKeyFactor>
  notValidAfter?: Maybe<Scalars['String']>
  notValidBefore?: Maybe<Scalars['String']>
}

export enum SystemKeyFactor {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum SystemState {
  Locked = 'locked',
  Online = 'online',
}

export type Systems = {
  __typename?: 'Systems'
  data?: Maybe<Array<Maybe<System>>>
  meta?: Maybe<PaginationMeta>
}

export type Tariff = {
  __typename?: 'Tariff'
  id: Scalars['ID']
  isDefault?: Maybe<Scalars['Boolean']>
  isSelected?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  params?: Maybe<Array<Maybe<TariffParam>>>
  procedure?: Maybe<Scalars['String']>
  product?: Maybe<Product>
  productID: Scalars['ID']
  status?: Maybe<TariffStatus>
}

export type TariffParam = {
  __typename?: 'TariffParam'
  amount: Scalars['String']
  currencyID?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  percentage: Scalars['String']
  serviceID?: Maybe<Scalars['ID']>
}

export enum TariffStatus {
  Active = 'active',
  Finished = 'finished',
}

export type Team = {
  __typename?: 'Team'
  client?: Maybe<Client>
  clientID: Scalars['ID']
  externalID?: Maybe<Scalars['String']>
  gameCategories: Array<GameCategory>
  gameExclusionExternalIDs: Array<Scalars['ID']>
  gameExclusions?: Maybe<Array<Game>>
  gameExternalIDs: Array<Scalars['ID']>
  games?: Maybe<Array<Game>>
  gamesCount?: Maybe<Scalars['String']>
  id: Scalars['ID']
  locationExclusionExternalIDs: Array<Scalars['ID']>
  locationExclusions?: Maybe<Array<Location>>
  locationExternalIDs: Array<Scalars['ID']>
  locationTypes: Array<LocationType>
  locations?: Maybe<Array<Location>>
  locationsCount?: Maybe<Scalars['String']>
  name: Scalars['String']
  playerExclusionExternalIDs: Array<Scalars['ID']>
  playerExclusions?: Maybe<Array<Player>>
  playerExclusionsCount?: Maybe<Scalars['Int']>
  playerExternalIDs: Array<Scalars['ID']>
  playerStatuses: Array<Scalars['String']>
  players?: Maybe<Array<Player>>
  playersCount?: Maybe<Scalars['Int']>
  type: TeamType
}

export type TeamGameExclusionsArgs = {
  limit?: InputMaybe<Scalars['Int']>
}

export type TeamGamesArgs = {
  limit?: InputMaybe<Scalars['Int']>
}

export type TeamLocationExclusionsArgs = {
  limit?: InputMaybe<Scalars['Int']>
}

export type TeamLocationsArgs = {
  limit?: InputMaybe<Scalars['Int']>
}

export type TeamPlayerExclusionsArgs = {
  limit?: InputMaybe<Scalars['Int']>
}

export type TeamPlayersArgs = {
  limit?: InputMaybe<Scalars['Int']>
}

export enum TeamType {
  Manual = 'manual',
  System = 'system',
}

export type Teams = {
  __typename?: 'Teams'
  data?: Maybe<Array<Maybe<Team>>>
  meta?: Maybe<PaginationMeta>
}

export type Template = ITemplate & {
  __typename?: 'Template'
  access?: Maybe<Scalars['String']>
  boundCount?: Maybe<Scalars['Int']>
  bounds?: Maybe<Array<Config>>
  configs?: Maybe<Array<Config>>
  gameParams?: Maybe<Array<Config>>
  id: Scalars['ID']
  levelsConfig?: Maybe<Array<LevelConfig>>
  paramCount?: Maybe<Scalars['Int']>
  params?: Maybe<Array<Config>>
  product: Product
  rank?: Maybe<Scalars['String']>
}

export type Templates = {
  __typename?: 'Templates'
  data?: Maybe<Array<Template>>
  meta?: Maybe<PaginationMeta>
}

export enum TokenType {
  Auth = 'auth',
  Mfa = 'mfa',
}

export type Transaction = {
  __typename?: 'Transaction'
  amount: Scalars['Float']
  amountType?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  currency?: Maybe<Currency>
  currencyID: Scalars['ID']
  destination: Client
  destinationClientId: Scalars['String']
  id: Scalars['ID']
  source: Client
  sourceClientId: Scalars['String']
}

export type UpdateIntegrant = {
  description?: InputMaybe<Scalars['String']>
  fullName?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
}

export type UpdateJackpot = {
  agreement?: InputMaybe<UpsertJackpotAgreement>
  externalCode?: InputMaybe<Scalars['String']>
  fundsMode?: InputMaybe<JackpotFundsMode>
  hitAutoApprove?: InputMaybe<Scalars['Float']>
  id: Scalars['ID']
  jackpotSystemID?: InputMaybe<Scalars['String']>
  levels?: InputMaybe<Array<UpsertJackpotLevel>>
  logo?: InputMaybe<Scalars['String']>
  product: UpsertJackpotProduct
  productID?: InputMaybe<Scalars['String']>
  reportDisableAsActive?: InputMaybe<Scalars['Boolean']>
  rng?: InputMaybe<Scalars['String']>
  runtime?: InputMaybe<Scalars['String']>
  scheduler?: InputMaybe<UpsertJackpotScheduler>
  templateID: Scalars['ID']
  tradeName?: InputMaybe<Scalars['String']>
  winMessage?: InputMaybe<Scalars['String']>
}

export type UpdateLocationAddress = {
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  house?: InputMaybe<Scalars['String']>
  region?: InputMaybe<Scalars['String']>
  street?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type UpdateProduct = {
  access?: InputMaybe<ProductAccess>
  accessClientsExclude?: InputMaybe<Array<Scalars['ID']>>
  accessClientsInclude?: InputMaybe<Array<Scalars['ID']>>
  allowedCurrencyIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  baseCurrencyID: Scalars['ID']
  clientID?: InputMaybe<Scalars['ID']>
  description?: InputMaybe<Scalars['String']>
  invoiceGenerationInterval?: InputMaybe<ProductInvoiceInterval>
  languageIDs?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  prepayment?: InputMaybe<Scalars['Float']>
  type?: InputMaybe<Scalars['String']>
}

export type UpdateProductAccess = {
  access?: InputMaybe<ProductAccess>
  accessClientsExclude?: InputMaybe<Array<Scalars['ID']>>
  accessClientsInclude?: InputMaybe<Array<Scalars['ID']>>
}

export enum UpdateSystemStateAction {
  Lock = 'lock',
  Unlock = 'unlock',
}

export type UpdateTariff = {
  id: Scalars['ID']
  isDefault?: InputMaybe<Scalars['Boolean']>
  status?: InputMaybe<TariffStatus>
}

export type UpdateTemplate = {
  configs: Array<UpdateTemplateConfig>
  id: Scalars['ID']
  product: UpdateTemplateProduct
}

export type UpdateTemplateConfig = {
  defaultValue: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  isVisible?: InputMaybe<Scalars['Boolean']>
  max?: InputMaybe<Scalars['String']>
  min?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  type?: InputMaybe<ConfigType>
  valueType?: InputMaybe<ConfigValueType>
  values?: InputMaybe<Array<Scalars['String']>>
}

export type UpdateTemplateProduct = {
  allowedCurrencyIDs?: InputMaybe<Array<Scalars['ID']>>
  baseCurrencyID: Scalars['ID']
  clientID: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  invoiceGenerationInterval?: InputMaybe<ProductInvoiceInterval>
  languageIDs?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  prepayment?: InputMaybe<Scalars['Float']>
}

export type UpdateUserEntityPermissions = {
  isAllowed: Scalars['Boolean']
  permission: Scalars['String']
}

export type UpsertAddress = {
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  house?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  index?: InputMaybe<Scalars['String']>
  region?: InputMaybe<Scalars['String']>
  street?: InputMaybe<Scalars['String']>
}

export type UpsertAnalytic = {
  filters?: InputMaybe<Array<InputMaybe<AnalyticFilterInput>>>
  id?: InputMaybe<Scalars['ID']>
  sort?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<Scalars['String']>
  type: Scalars['String']
}

export type UpsertAwardLimit = {
  code: Scalars['String']
  id?: InputMaybe<Scalars['ID']>
  levelID?: InputMaybe<Scalars['ID']>
  value?: InputMaybe<Scalars['Float']>
}

export type UpsertBankingPayment = {
  actionID?: InputMaybe<Scalars['ID']>
  agreementID?: InputMaybe<Scalars['ID']>
  clientID?: InputMaybe<Scalars['ID']>
  details?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  invoice?: InputMaybe<Scalars['String']>
  invoiceDate?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Scalars['String']>
  orderDate?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  transaction?: InputMaybe<UpsertTransaction>
}

export type UpsertClient = {
  about?: InputMaybe<Scalars['String']>
  address?: InputMaybe<UpsertAddress>
  allowedCurrencyIDs: Array<Scalars['ID']>
  contractDate: Scalars['String']
  contractID: Scalars['String']
  email: Scalars['String']
  fullName: Scalars['String']
  id?: InputMaybe<Scalars['ID']>
  isBlocked?: InputMaybe<Scalars['Boolean']>
  license?: InputMaybe<Scalars['String']>
  maxUsersActiveSession?: InputMaybe<Scalars['Int']>
  maxUsersInactiveSession?: InputMaybe<Scalars['Int']>
  minBalances?: InputMaybe<Array<InputMaybe<UpsertMinBalance>>>
  name: Scalars['String']
  phone?: InputMaybe<Scalars['String']>
  role: ClientRole
  specializationIDs?: InputMaybe<Array<Scalars['String']>>
  state?: InputMaybe<ClientState>
  usersPasswordPeriod?: InputMaybe<Scalars['Int']>
}

export type UpsertJackpotAgreement = {
  currencyID: Scalars['ID']
  tariffID: Scalars['ID']
}

export type UpsertJackpotEntry = {
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
}

export type UpsertJackpotLevel = {
  activationBets?: InputMaybe<Scalars['Float']>
  activationGames?: InputMaybe<Scalars['Float']>
  activationTime?: InputMaybe<Scalars['Float']>
  activityBreak?: InputMaybe<Scalars['Float']>
  awardBase?: InputMaybe<Scalars['String']>
  awardBaseMaxDrawTime?: InputMaybe<Scalars['Float']>
  awardBaseMaxTotalBet?: InputMaybe<Scalars['Float']>
  awardBaseMinDrawTime?: InputMaybe<Scalars['Float']>
  awardBaseMinTotalBet?: InputMaybe<Scalars['Float']>
  awardLimits?: InputMaybe<Array<InputMaybe<UpsertAwardLimit>>>
  awardServe?: InputMaybe<Scalars['String']>
  awardType?: InputMaybe<LevelAwardType>
  awardValue?: InputMaybe<Scalars['Float']>
  awardWagerMultiplicator?: InputMaybe<Scalars['Float']>
  code?: InputMaybe<Scalars['Float']>
  contribution?: InputMaybe<LevelContributionType>
  endDate?: InputMaybe<Scalars['String']>
  eventAmountType?: InputMaybe<Scalars['String']>
  eventType?: InputMaybe<Scalars['String']>
  externalCode?: InputMaybe<Scalars['String']>
  externalCongratulatorCode?: InputMaybe<Scalars['String']>
  fundBase?: InputMaybe<Scalars['Float']>
  fundIncrement?: InputMaybe<Scalars['Float']>
  fundIncrementMethod?: InputMaybe<Scalars['String']>
  fundLimit?: InputMaybe<Scalars['Float']>
  fundOverflowIncrement?: InputMaybe<Scalars['Float']>
  fundOverflowIncrementMethod?: InputMaybe<Scalars['String']>
  fundReserveIncrement?: InputMaybe<Scalars['Float']>
  fundReserveIncrementMethod?: InputMaybe<Scalars['String']>
  fundResetOverflow?: InputMaybe<Scalars['Float']>
  fundResetReserve?: InputMaybe<Scalars['Float']>
  fundResetValue?: InputMaybe<Scalars['Float']>
  fundSecondaryIncrement?: InputMaybe<Scalars['Float']>
  fundSecondaryIncrementMethod?: InputMaybe<Scalars['String']>
  hitAutoApprove?: InputMaybe<Scalars['Float']>
  hits?: InputMaybe<Scalars['Float']>
  id?: InputMaybe<Scalars['ID']>
  index: Scalars['Int']
  loseSession?: InputMaybe<Scalars['Int']>
  manualHitDistribution?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  maxBet?: InputMaybe<Scalars['Float']>
  minBet?: InputMaybe<Scalars['Float']>
  mostValuableParticipant?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  participantType?: InputMaybe<Scalars['String']>
  period?: InputMaybe<Scalars['Int']>
  rankDistributionRule?: InputMaybe<Scalars['String']>
  rankHitShare?: InputMaybe<Scalars['Float']>
  rankLimit?: InputMaybe<Scalars['Float']>
  rankScope?: InputMaybe<Scalars['String']>
  runtime?: InputMaybe<Scalars['String']>
  scoreStrategy?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  state?: InputMaybe<LevelStateType>
  winRate?: InputMaybe<Scalars['Int']>
  winSession?: InputMaybe<Scalars['Int']>
}

export type UpsertJackpotProduct = {
  access?: InputMaybe<ProductAccess>
  accessClientsExclude?: InputMaybe<Array<Scalars['ID']>>
  accessClientsInclude?: InputMaybe<Array<Scalars['ID']>>
  allowedCurrencyIDs?: InputMaybe<Array<Scalars['ID']>>
  baseCurrencyID: Scalars['ID']
  clientID: Scalars['ID']
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  invoiceGenerationInterval?: InputMaybe<ProductInvoiceInterval>
  languageIDs?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  prepayment?: InputMaybe<Scalars['Float']>
  type?: InputMaybe<Scalars['String']>
}

export type UpsertJackpotScheduler = {
  activitySpans?: InputMaybe<Array<InputMaybe<UpsertJackpotSchedulerSpan>>>
  spansEndAt?: InputMaybe<Scalars['String']>
  spansStartAt?: InputMaybe<Scalars['String']>
  state: JackpotSchedulerState
}

export type UpsertJackpotSchedulerSpan = {
  calendarColor?: InputMaybe<Scalars['String']>
  endSec: Scalars['Int']
  id?: InputMaybe<Scalars['ID']>
  levelHitLimits?: InputMaybe<Array<InputMaybe<UpsertJackpotSchedulerSpanHitLimits>>>
  startSec: Scalars['Int']
}

export type UpsertJackpotSchedulerSpanHitLimits = {
  hitLimit: Scalars['Float']
  levelID: Scalars['ID']
}

export type UpsertLocation = {
  address?: InputMaybe<UpdateLocationAddress>
  clientID?: InputMaybe<Scalars['ID']>
  details?: InputMaybe<Scalars['String']>
  externalID?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  typeID?: InputMaybe<Scalars['ID']>
  url?: InputMaybe<Scalars['String']>
}

export type UpsertMinBalance = {
  id: Scalars['ID']
  value: Scalars['Float']
}

export type UpsertSubscribe = {
  enabled?: InputMaybe<Scalars['Boolean']>
  topic: Scalars['String']
  url?: InputMaybe<Scalars['String']>
}

export type UpsertTeam = {
  clientID?: InputMaybe<Scalars['ID']>
  gameCategories?: InputMaybe<Array<Scalars['String']>>
  gameExclusionExternalIDs?: InputMaybe<Array<Scalars['ID']>>
  gameExternalIDs?: InputMaybe<Array<Scalars['ID']>>
  id?: InputMaybe<Scalars['ID']>
  locationExclusionExternalIDs?: InputMaybe<Array<Scalars['ID']>>
  locationExternalIDs?: InputMaybe<Array<Scalars['ID']>>
  locationTypes?: InputMaybe<Array<Scalars['String']>>
  name?: InputMaybe<Scalars['String']>
  playerExclusionExternalIDs?: InputMaybe<Array<Scalars['ID']>>
  playerExternalIDs?: InputMaybe<Array<Scalars['ID']>>
  playerStatuses?: InputMaybe<Array<Scalars['String']>>
  type?: InputMaybe<TeamType>
}

export type UpsertTransaction = {
  amount: Scalars['Float']
  currencyID: Scalars['ID']
}

export type UpsertUser = {
  clientID?: InputMaybe<Scalars['ID']>
  email?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  languageID?: InputMaybe<Scalars['ID']>
  permissionIDs: Array<Scalars['Int']>
  permissions?: InputMaybe<Array<UpdateUserEntityPermissions>>
  phone?: InputMaybe<Scalars['String']>
  role: UserRole
  state?: InputMaybe<UserState>
}

export type User = {
  __typename?: 'User'
  client?: Maybe<Client>
  clientID: Scalars['ID']
  controlledClients?: Maybe<Array<Maybe<Client>>>
  controlledClientsIDs: Array<Scalars['ID']>
  dataActions?: Maybe<UserDataAction>
  email: Scalars['String']
  id: Scalars['ID']
  is2FAEnabled?: Maybe<Scalars['Boolean']>
  isMailSendDisabled?: Maybe<Scalars['Boolean']>
  language?: Maybe<Scalars['String']>
  languageID: Scalars['ID']
  lastActivityDate: Scalars['String']
  permissionIDs: Array<Scalars['Int']>
  permissionPeriod?: Maybe<Scalars['Int']>
  permissionStartDate?: Maybe<Scalars['String']>
  permissions?: Maybe<Array<AccessRightsPermission>>
  phone?: Maybe<Scalars['String']>
  registerStartDate?: Maybe<Scalars['String']>
  registrationDate?: Maybe<Scalars['String']>
  role: UserRole
  sessionStartDate?: Maybe<Scalars['String']>
  state?: Maybe<UserState>
  status?: Maybe<UserStatus>
  updatePasswordDate?: Maybe<Scalars['String']>
}

export type UserDataAction = {
  __typename?: 'UserDataAction'
  changePassword?: Maybe<Scalars['Boolean']>
  edit?: Maybe<Scalars['Boolean']>
  lock?: Maybe<Scalars['Boolean']>
  resetTotp?: Maybe<Scalars['Boolean']>
}

export enum UserRole {
  Employee = 'employee',
  Owner = 'owner',
}

export enum UserState {
  Active = 'active',
  Blocked = 'blocked',
  Created = 'created',
  Declined = 'declined',
  Expired = 'expired',
  Pending = 'pending',
}

export enum UserStatus {
  Locked = 'locked',
  Offline = 'offline',
  Online = 'online',
}

export type Users = {
  __typename?: 'Users'
  data?: Maybe<Array<Maybe<User>>>
  meta?: Maybe<PaginationMeta>
}

export type ValidateTokenResponse = {
  __typename?: 'ValidateTokenResponse'
  email?: Maybe<Scalars['String']>
}

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    IBoundConfig: ['BoundConfig', 'DefaultBoundConfig'],
    IConfig: ['Config', 'DefaultConfig'],
    ILevelConfig: ['DefaultLevelConfig', 'LevelConfig'],
    ITemplate: ['DefaultTemplate', 'Template'],
    ProductAndService: ['AgreementV', 'ProductV'],
  },
}
export default result
